import AppRouter from './routes/AppRouter';
import './styles/App.css';

function App() {
  return (<>
    <AppRouter/>
  </>);
}

export default App;
