import React, { useEffect, useState } from 'react'
import sliderData from '../assets/slider.data'
import FooterDataView from '../pages/FooterDataView'
import FooterView from '../pages/FooterView'
import HeroView from '../pages/HeroView'
import MisionView from '../pages/MisionView'
import SuplyView from '../pages/SuplyView'
import TitleView from '../pages/Titles'
import VisionView from '../pages/VisionView'
import TestContainer from './TestComponent'

const HomeContainer = () =>{
    const [works, setWorks] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    useEffect(() => {
        const wakeUpBack = async () => {
            setLoading(true)
            const endPoint = `api/home`;
            const service = process.env.REACT_APP_API_REST;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type':'applicaton/json'
                }
            }
            try {
                const response = await fetch(`${service}${endPoint}`,options);
                const data = response.json();
                setWorks(data);
                setLoading(false);
            } catch (error) {
                setError(error)
            }
        };
        wakeUpBack();
    }, []);
    return(<>
        <HeroView slides={sliderData}/>
        <SuplyView/>
        <TitleView title={'Mision'} classname={'titleMision'}/>
        <MisionView/>
        <TitleView title={'Vision'}/>
        <VisionView/>
        <FooterView/>
        <FooterDataView/>
        <TestContainer works={works} loading={loading} error={error}/>
    </>)
}
export default HomeContainer