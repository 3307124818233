import React  from 'react';
import CardContainer from './CardContainer';
import image from '../assets/img/seguridad.jpg'
import image2 from '../assets/img/quirurgicos.jpg'
import image3 from '../assets/img/stationerymockup.jpg'
import image4 from '../assets/img/consulting.jpg'


const CardPayload = ()=>{
    return(<>
        <div className='payload'>
            <CardContainer className='cardContainer' image={image} description={'Seguridad Industrial'}/>
            <CardContainer className='cardContainer' image={image2} description={'Quirurgicos'}/>
            <CardContainer className='cardContainer' image={image3} description={'Papeleria'}/>
            <CardContainer className='cardContainer' image={image4} description={'Consultorias SST'}/>
        </div>
    </>)
}

export default CardPayload 