import React from 'react'
import CardView from '../pages/Cardview'


const CardContainer = ({image,description}) =>{
    return(<>
        <CardView image={image} description={description}/>
    </>)
}

export default CardContainer