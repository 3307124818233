import React from 'react';

const TestContainer = ({works,loading,error}) => {
    console.log(loading)
    if(loading) return <div>loading</div>
    if(error) return <div>ups Error</div>
    return(
        <div>{works.message}</div>
    )
}

export default TestContainer