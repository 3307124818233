import React from 'react';
import image from '../assets/img/insuofice.png'

const ProductsView = () =>{

    return(<>
        <div className='showcaseContainer'>
        <div className="showcaseTrhee">
            <div className="textTwo">
                <div className='LigoSuplytwo'>
                    <img src={image} alt="Logo" height='200' />
                </div>
                <p>Comercializamos una gran variedad de productos para el hogar, Linea institucional y linea industrial, brindando una solucion integral a todas las necesidades del mercado</p>
            </div>          
        </div>
        </div>
    </>)
}

export default ProductsView