import { Button, CardActions, CardContent, Typography } from '@material-ui/core';
import React from 'react'
import Modal from 'react-modal'

const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background:'#FFFF',
      color:'#004BAD'
    },
  };
  
const ModalView =  ({modalIsOpen,openModal,closeModal,message,error,loading}) => {
    if(loading) return <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    if(error) return <div>Error</div>
    return(
    <div>
        <Button type='submit' variant='contained' color='primary' onClick={openModal}>Enviar</Button>
        <Modal
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={styles}
            contentLabel="Example Modal">
                {
                    !message ?
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    :
                        <>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {message.status}
                                </Typography>
                                <Typography  color="text.secondary">
                                   {message.message}
                                </Typography>
                                <span>&#127939;</span>
                            </CardContent>
                            <CardActions>
                                <Button variant='contained' color='primary' onClick={closeModal}>OK</Button>
                            </CardActions>
                        </>
                }
        </Modal>
    </div>
    )
}

export default ModalView