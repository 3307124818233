import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import React from 'react'

const CardView = ({image,description}) =>{
    return(<>
        <div>
            <div>
                <div className="client1">
                    <div className="theCard">
                        <div className="thefront">
                        <Card>
                            <CardActionArea>
                                <h2 className='cardTitle'>{description}</h2>
                                <CardMedia
                                component="img"
                                image={image}
                                height="600"
                                title="Contemplative Reptile"
                                />
                            </CardActionArea>
                        </Card>
                        </div>
                        <div className="theBack">
                        <Card>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {description}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat laboriosam suscipit ipsum quas blanditiis, animi repudiandae minima. Et, voluptatem nemo? Accusamus, aut, quo, debitis exercitationem officia accusantium est illum sunt non officiis deleniti. Molestiae voluptas numquam incidunt minima corrupti, fugit ratione assumenda velit eius dolor sed at, pariatur, maxime ad nam facilis quos optio asperiores reprehenderit vitae! Vero sunt doloribus vitae nostrum praesentium facere totam corporis! Unde quia harum non totam voluptates, quae quisquam magnam suscipit eaque, minus fuga porro debitis molestiae praesentium saepe aspernatur placeat ullam dolor quaerat itaque id repudiandae sapiente libero cumque. Iste sunt provident possimus autem aliquam magni dolorem adipisci ex! Ipsa velit, voluptates porro unde labore dolor similique eaque natus. Commodi itaque cumque corrupti corporis ratione saepe accusantium sed odio, fugiat voluptate explicabo dolore nesciunt alias dolorum maxime nihil! Amet quas delectus sint minus, saepe totam suscipit a neque. Vero totam fugiat deserunt, quos quam blanditiis corporis porro ex ut cum ducimus consectetur officiis deleniti possimus est ipsam animi minus illum cumque quis necessitatibus? Id adipisci quae nesciunt perspiciatis est delectus illum minima nulla libero. Cumque quis reiciendis eaque quibusdam enim odio hic. Cupiditate sint qui expedita voluptatum nesciunt soluta cumque, reprehenderit officia sit accusamus.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default CardView