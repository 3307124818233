const sliderData =  [
    {
        image: 'https://imagesapirestmarketplace.s3.us-east-2.amazonaws.com/Home.jpg'
    },
    {
        image: 'https://imagesapirestmarketplace.s3.us-east-2.amazonaws.com/marketplace.jpg'
    },
    {
        image: 'https://imagesapirestmarketplace.s3.us-east-2.amazonaws.com/marketplace2.jpg'
    },
    {
        image: 'https://imagesapirestmarketplace.s3.us-east-2.amazonaws.com/Travel.jpg'
    }
]

export default sliderData