import React from 'react';
import image from '../assets/img/insuofice.png'

const FooterDataView = () =>{
    return(<>
        <div className='navImageFootercont'>
            <div className='navImageFooter'>
                <img src={image} alt="logo" height='100'/>
                <p>&nbsp;+57&nbsp;(1)&nbsp;1234567&nbsp;+57&nbsp;313245234&nbsp;<span>carrera&nbsp;1234&nbsp;#&nbsp;2341&nbsp;-&nbsp;23</span></p>
            </div>
        </div>
    </>)
}

export default FooterDataView