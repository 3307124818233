import React from 'react'
import image from '../assets/img/insuofice.png'

const SuplyView = ()=>{
    return(<>
        <div className="showcase2">
            <div className="text">
                <div className='LigoSuply'>
                    <img src={image} alt="Logo" height='200' />
                </div>
                <p>En Insuoffice, comercializamos productos de alta calidad con las mejores tarifas del mercado</p>
            </div>          
        </div>
    </>)
}

export default SuplyView