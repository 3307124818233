import { useFormik } from 'formik';
import React from 'react';
import ContactUsView from './ContactUsView';
import *as yup from 'yup';

const ContactUsContainer = ({sendEmail,loading,message,error}) => {
    const validatorSchema = yup.object({
        name: yup.string('Ingresa tu nombre').required('Este campo es requerido').max(40).min(3),
        email: yup.string('Ingresa tu email').required('Este campo es requerido').email(),
        telephone: yup.string('Ingresa tu numero telefonico').required('Este campo es requerido'),
        message: yup.string('Ingresa el mensaje').required('Este campo es requerido').max(500)
    })
    const formik = useFormik({
        initialValues:{
            name :'',
            email:'',
            telephone:'',
            message: ''
        },
        validationSchema:validatorSchema,
        onSubmit:(values)=>{
            sendEmail(values)
            formik.resetForm()
        }
    })
    return(
        <ContactUsView formik={formik} loading={loading} message={message} error={error}/>
    );
};

export default ContactUsContainer