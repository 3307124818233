import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import image from '../assets/img/insuofice.png'
import { NavLink } from 'react-router-dom';




const NaviationComponent =() =>{
  return (
    <div className='navBarindex'>
      <AppBar position="fixed" className='navBarCustom'color='inherit'>        
        <Toolbar variant="dense" className='navToolbar'>
          <div className='navImage'>
            <img src={image} alt="logo" height='70'/>
          </div>
          <ul className='NavList'>
            <li className='NavItems'>
              <Button variant="outlined" size='medium' color="primary">
                <NavLink className='Navlinks' exact to='/'>Nosotros</NavLink>
              </Button>

            </li>
            <li className='NavItems'>
              <Button variant="outlined" size='medium' color="primary">
                <NavLink className='Navlinks' exact to='/products'>Productos</NavLink>
              </Button>
            </li>
          </ul>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NaviationComponent