import React, { useState } from 'react';
import ModalView from './ModalView';

const ModalContainer = ({loading,message,error}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const openModal = () => {
            setIsOpen(true);
    }
        
    const closeModal = () => {
            setIsOpen(false);
    }
    return(
        <ModalView 
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            message={message} 
            error={error}
            loading={loading}/>
    )
}

export default ModalContainer;
