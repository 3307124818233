import React from 'react'

import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import HomeContainer from '../components/HomeContainer'
import NaviationComponent from '../components/NavigationComponent'
import ProductsContainer from '../components/ProductsContainer'
import PreNavBarView from '../pages/PreNavBarView'



const AppRouter = () =>{

    return(<>
        <Router>
            <PreNavBarView/>
            <NaviationComponent/>
            <Switch>
                <Route exact path={'/'}>
                    <HomeContainer/>
                </Route>
                <Route exact path={'/products'}>
                    <ProductsContainer/>
                </Route>
            </Switch>



        </Router>
    
    </>)
}

export default AppRouter