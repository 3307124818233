import React, { useState } from 'react';
import FooterDataView from '../pages/FooterDataView';
import FooterView from '../pages/FooterView';
import ProductsView from '../pages/ProductsView';
import CardPayload from './CardPayload';
import ContactUsContainer from './ContactUsContainer.';

const ProductsContainer = () =>{    
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    
    const sendEmail = async(object) => {        
        const values = object
        const service = process.env.REACT_APP_API_REST;
        const endPoint = `api/send-email`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(values)
        }
        try {
            setLoading(true)
            const response = await fetch(`${service}${endPoint}`,options)
            const data = await response.json()
            setMessage(data)
            setLoading(false)

        } catch (error) {
            setError(error)
        }
    }
    return(<>
        <ProductsView/>
        <CardPayload/>
        <FooterView/>
        <ContactUsContainer sendEmail={sendEmail} loading={loading} message={message} error={error}/>
        <FooterDataView/>
    </>)
}

export default ProductsContainer