import {Card, CardActions, CardContent, TextField } from '@material-ui/core';
import React from 'react';
import ModalContainer from './ModalContainer';

const ContactUsView = ({formik,loading,message,error}) => {
    return(
        <form className='contactForm'onSubmit={formik.handleSubmit}>
        <Card className={'cardLogin'}>
            <CardContent className='cardLoginContent'>
                <h2 className='titleContact'>Ingresa tus datos y el mensaje</h2>
                <TextField id='name'
                    className='fieldsInput' 
                    type='text'
                    label="Nombres y apellidos"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}/>
                <TextField id='email'
                    className='fieldsInput' 
                    type='email'
                    label="Correo Electronico"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}/>
                <TextField id='telephone'
                    className='fieldsInput'     
                    placeholder='ejemplo 3051234567'
                    type='number' 
                    label="Numero de contacto"
                    variant="outlined"
                    value={formik.values.telephone}
                    onChange={formik.handleChange}
                    error={formik.touched.telephone && Boolean(formik.errors.telephone)}/>
                <TextField id='message'
                    className='fieldsInput'     
                    type='text' 
                    label="Mensaje"
                    variant="outlined"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}/>
            </CardContent>
            <CardActions className='cadAccContent'>
                <ModalContainer loading={loading} message={message} error={error}/>
            </CardActions>
        </Card>
    </form>
    );
}

export default ContactUsView;