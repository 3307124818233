import React from 'react'
import {FaFacebook} from 'react-icons/fa'
import {AiFillInstagram,AiFillYoutube} from 'react-icons/ai'

const FooterView = ()=>{
    return(<>
        <footer>
            <section className="social">
                <h2>Siguenos en</h2>
                <div className="wrapper">
                    <div className="icon facebook">
                        <div className="tooltip">Facebook</div>
                        <span><FaFacebook size={40}/></span>
                    </div>  
                    <div className="icon instagram">
                        <div className="tooltip">Instagram</div>
                        <span><AiFillInstagram size={40}/></span>
                    </div>
                    <div className="icon youtube">
                        <div className="tooltip">Youtube</div>
                        <span><AiFillYoutube size={40}/></span>
                    </div>
                </div>
            </section>
        </footer>
    </>)
}

export default FooterView