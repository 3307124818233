import React from 'react';
import image from '../assets/img/insuofice.png'

const VisionView = () =>{

    return(<>
        <div className='showcaseContainerVision'>
        <div className="showcaseTrheeVision">
            <div className="textTwoVision">
                <div className='LigoSuplytwoVision'>
                    <img src={image} alt="Logo" height='200' />
                </div>
                <p>posicionarnos en la industria con productos de alta calidad, siendo reconocidos como una empresa estable a lo largo del tiempo, brindando a nuestros colaboradores y clientes espacios de trabajo agradables y, entornos que nos acrediten como una organización con responsabilidad social y con sentido de pertenencia.</p>
            </div>          
        </div>
        </div>
    </>)
}

export default VisionView