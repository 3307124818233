import React from 'react';
import image from '../assets/img/insuofice.png'

const MisionView = () =>{

    return(<>
        <div className='showcaseContainerMision'>
        <div className="showcaseTrheeMision">
            <div className="textTwoMision">
                <div className='LigoSuplytwoMision'>
                    <img src={image} alt="Logo" height='200' />
                </div>
                <p>Comercializar productos de aseo, cafetería, papelería y elementos de seguridad en el canal industrial y doméstico, ofreciendo soluciones y alternativas efectivas que satisfagan las necesidades de nuestros clientes con calidad, precio, cumplimiento y funcionalidad.</p>
            </div>          
        </div>
        </div>
    </>)
}

export default MisionView