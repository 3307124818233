import React from 'react'
import { FcCellPhone, FcPhone, FcDam } from 'react-icons/fc'

const PreNavBarView = () =>{
    return(<>
        <div className='preNavbar'>
            <ul className='prenavBarinfo'>
                <li className='preNavItems'><p><FcPhone/>&nbsp;+57&nbsp;(1)&nbsp;1234567</p></li>
                <li className='preNavItems'><p><FcCellPhone/>&nbsp;+57&nbsp;313245234</p></li>
                <li className='preNavItems'><p><FcDam/>&nbsp;<span>carrera&nbsp;1234&nbsp;#&nbsp;2341&nbsp;-&nbsp;234</span></p></li>
            </ul>        
      </div>
    </>)
}

export default PreNavBarView